.buttons-container {
  @include flexCenter();
}

.buttons {
  width: 10rem;
  height: 3rem;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  border: 2px solid #495057;
  border-radius: 10px/20px;
  background: #495057;
  margin: 1rem;
}
.buttons:hover {
  background: #fff;
  color: #495057;
  border-radius: 10px/20px;
}
