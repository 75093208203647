.modal {
  @include flexCenter();
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.modal-scope {
  @include flexCenter();
  position: relative;
  flex-direction: column;
  width: 480px;
  height: 380px;
  background: #fff;
  padding: 2rem;
  border-radius: 0.6rem;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.close {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  padding: 0.5rem;
  background: #666dcab5;
  border-radius: 5rem;
  font-weight: bold;
  color: #ff004c;
}

.close:hover {
  background: #a5a3a31f;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.modal .solution {
  color: #ff004c;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.modal .tiles {
  @include flexCenter();
  flex-direction: row;
  flex-wrap: nowrap;
}

.modal .modal-scope > p,
div,
h1 {
  margin: 10px 0;
}

.modal .tiles > span {
  width: 40px;
  height: 40px;
  margin: 4px;
  text-align: center;
  line-height: 40px;
  background: $wrongPositionBgColor;
}

.modal .tiles > .correct-position {
  background: $correctPositionBgColor;
}

.modal .tiles > .wrong-position {
  background: $correctWithoutBgPosition;
}

@media (max-width: 480px) {
  .modal-scope {
    width: 90%; /* adjust the width to fill the viewport */
    height: 90%; /* adjust the height to fill the viewport */
    padding: auto;
  }
  .close {
    top: 1rem;
    right: 1rem;
  }
}
