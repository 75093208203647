nav {
  position: sticky;
  @include flexCenter();
  @include cardFont();
  min-width: 50%;
  height: 3rem;
  background-color: $navBarBgColor;
  border: 3px solid #33333383;
  border-radius: 15px 15px 0 0;
  margin-bottom: 2rem;

  ul {
    width: auto;
    @include flexCenter();
    align-items: center;
    flex-direction: row;
    list-style-type: none;
    padding-inline-start: 0; /* remove space before the first inline element */
  }

  li {
    list-style-type: none;
    padding: 1rem;
    max-width: 100%; /* ensure that the li element fits within the nav element */
    overflow: hidden; /* prevent content from extending beyond the li element */
  }

  li a {
    padding: 0;
    text-decoration: none;
    margin: 0 1rem;
    font-weight: bold;
    color: $navTextColor;
  }

  li a span {
    color: $navIconsColor;
  }
}

ul {
  margin-left: 0;
  padding-left: 0;
}

#last a {
  margin-left: 0;
  padding-left: 0;
}

#web {
  text-decoration: none;
  margin-left: 4px;
}

#copy {
  font-size: 1.5rem;
  font-weight: bold;
}

@media screen and (min-width: 500px) {
  nav {
    min-width: 50%;
    margin: 0 25%;
  }
}
