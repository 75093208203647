.question {
  text-align: center;
  font-size: 0.8rem;
}

.position {
  @include flexCenter();
  margin: 0.5rem 0 0 0;
  padding: 0;
}

.position > div {
  width: 5.5rem;
  @include cardFont();
  color: #333333;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .position > div {
    width: 4.688rem;
  }
}
